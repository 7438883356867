import React, { useEffect, useRef, useState } from 'react'
import { Pencil, Search } from '../../common/Icons'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toaster } from '../../apis/commonFunctions'
import { getRequest, patchRequest } from '../../apis/functions'
import APIS from '../../apis/routes'
import NoDataRow from '../../common/NoDataRow'
import moment from 'moment'
import StatusPopup from '../../common/popups/StatusPopup'
import Dropdown from '../../common/Dropdown'
import Search_input from '../../common/Search_input'
import html2pdf from 'html2pdf.js';


export default function EmiManagement() {
    const [all, setAll] = useState([]);
    const nav = useNavigate()
    const contentRef = useRef(null)
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(8);
    const [mainid, setMainId] = useState("");
    const [dates, setDates] = useState([]);
    const [total, setTotal] = useState(0)
    const router = useNavigate();
    const [params] = useSearchParams();
    // console.log(Array.from(params.entries())," thishsishihsih")
    const search = params.get('search')

    let currentMonth = moment().startOf('month')
    const [selected, selectDate] = useState({
        month: currentMonth.format('YYYY-MM'),
        name: currentMonth.format('MMM YYYY'),
        shop_name: currentMonth.format('MMM YYYY'),
        _id: currentMonth.format('MMM YYYY'),
        firstDateTimestamp: currentMonth.startOf('month').valueOf(),
        lastDateTimestamp: currentMonth.endOf('month').valueOf()
    })

    const convertToPdf = () => {
        const content = contentRef.current;

        const options = {
            filename: 'my-document.pdf',
            margin: 0.5,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 2 },
            jsPDF: {
                unit: 'in',
                format: 'A4',
                orientation: 'portrait',
            },
        };

        html2pdf().set(options).from(content).save();
    };


    const fetchData = async () => {
        try {
            const result = await getRequest(`${APIS.EMI}?search=${search ?? ""}&min=${selected.firstDateTimestamp}&max=${selected.lastDateTimestamp}&skip=${skip}&limit=${limit}`);
            if (!result.data.status) {
                toaster(result.data.message, false)
            } else {
                setAll(result.data.data)
                setTotal(result.data.total_count)

            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    const fetchMonths = async () => {
        try {
            const result = await getRequest(APIS.MONTHS);
            if (!result.data.status) {
                toaster(result.data.message, false)
            } else {
                setDates(result.data.data)
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    const updateStatus = async (status) => {
        try {
            const { data } = await patchRequest(`${APIS.EMI}/${mainid}`, { status });
            if (!data.status) {
                toaster(data.message, false)
            } else {
                toaster(data.message, true)
                fetchData()
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    function filteredResult(id) {
        selectDate(dates.filter(item => item._id === id)[0])
    }

    useEffect(() => {
        fetchData();
    }, [search, selected])

    async function exportPdf() {
        try {
            const { data } = await getRequest(APIS.DOWNLOAD);
            if (data.status) {
                toaster(data.message, true)
                setTimeout(() => window.open(process.env.REACT_APP_IMAGE_URL + 'result.pdf', '_blank'), 3000)
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err.message, false)
        }
    }

    useEffect(() => {
        fetchMonths()
    }, [])

    return (
        <>
            <div className="top_bar row align-items-center mt-3">
                <div className="col-lg-12 d-flex justify-content-between align-items-center">
                    <div className="pagetitle">
                        Upcoming EMIs
                    </div>
                    <div className='d-flex align-items-center' style={{ gap: "10px" }}>
                        <Search_input placeholder={'Search here'} search={search} handleSearch={(e) => router('?search=' + (e.nativeEvent.data === " " ? search + "+" : e.target.value))} icon={<Search />} className='search_input' />
                        <Dropdown title={selected._id} onSelect={(id) => filteredResult(id)} options={dates} style={{ marginTop: "0px" }} />
                        <button onClick={convertToPdf} className='btn btn-success' style={{ height: "50px" }}>Export as PDF</button>
                    </div>
                </div>
                {/* <div className="col-lg-6 text-end">
                    <Button text="Add Retailer" className="btn btn-success" onClick={() => nav("add")} /> */}
                {/* <Search_input  placeholder={'Search here'} search={key}  onChange={(e)=>setKey(e.target.value)} icon={<Search />} className='search_input mt-3' /> */}
                {/* </div> */}
            </div>
            <div className="row mt-3" ref={contentRef}>
                <div className="table-responsive">
                    <table className='table table-lg'>
                        <thead className='table-light'>
                            <tr colSpan="4">
                                <th>S.No</th>
                                <th>Name</th>
                                <th>Mobile No.</th>
                                <th>Amount</th>
                                <th>Fine</th>
                                <th>Total</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {all.length > 0
                                ?
                                all.map((element, index) => (
                                    <tr key={element._id}>
                                        <td>{index + 1}</td>
                                        <td style={{ textTransform: "inherit" }}>{element.name}</td>
                                        <td style={{ textTransform: "unset" }}>{element.number}</td>
                                        <td style={{ textTransform: "unset" }}>₹{element.payble_amount}</td>
                                        <td style={{ textTransform: "unset" }}>₹{element.fine}</td>
                                        <td style={{ textTransform: "unset" }}>₹{element.total_amount}</td>
                                        <td style={{ textTransform: "unset" }}>{moment(element.scheduled_for).format("DD-MMM-YYYY")}</td>
                                        <td style={{ textTransform: "unset" }}>{element.status}</td>
                                        <td>
                                            <div className="actions">
                                                {/* <button className='view p-2'>
                                                    <Link className='view' to={'/user-management/' + element._id}>
                                                        <Eye />
                                                    </Link>
                                                </button> */}
                                                {/* <button className='block' onClick={() => { blockUnblockUser(element._id) }} style={{ color: (element.admin_blocked ? "#0F993E" : "#ff4c27") }}>
                                                    <Block />
                                                </button> */}
                                                <button className='delete' onClick={() => setMainId(element._id)} data-bs-toggle="modal" data-bs-target="#decline_modal">
                                                    <Pencil />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                : <NoDataRow />}
                        </tbody>
                    </table>
                </div>
            </div>
            <StatusPopup message={"Please select to update"} onUpdate={(s) => updateStatus(s)} />
        </>
    )
}
