import React, { useEffect, useState } from 'react'
import Input from '../../common/Input'
import { patchRequest } from '../../apis/functions';
import { toaster } from '../../apis/commonFunctions';
import { useNavigate } from 'react-router-dom';
import APIS from '../../apis/routes'

const Editor = ({ datum, id }) => {
    const [details, setData] = useState();
    const nav = useNavigate()
    const [aadhar_front, setAadharFront] = useState(null);
    const [aadhar_back, setAadharBack] = useState(null);
    const [pancard_photo, setPanCardPhoto] = useState(null);
    const [statement1, setStatement1] = useState(null);
    const [statement2, setStatement2] = useState(null);
    const [sealed_box_photo, setSealedBox] = useState(null);
    const [sealed_box_back, setSealedBoxBack] = useState(null);
    const [signature, setSignature] = useState(null);
    const [bill_photo, setBillPhoto] = useState(null);
    const [customer_recieved_photo, setReceivedPhoto] = useState(null);

    function handleFile(e) {
        switch (e.target.name) {
            case "customer_recieved_photo":
                setReceivedPhoto(e.target.files[0]);
                break;
            case "bill_photo":
                setBillPhoto(e.target.files[0]);
                break;
            case "signature":
                setSignature(e.target.files[0]);
                break;
            case "sealed_box_back":
                setSealedBoxBack(e.target.files[0]);
                break;
            case "sealed_box_photo":
                setSealedBox(e.target.files[0]);
                break;
            case "statement2":
                setStatement2(e.target.files[0]);
                break;
            case "statement1":
                setStatement1(e.target.files[0]);
                break;
            case "pancard_photo":
                setPanCardPhoto(e.target.files[0]);
                break;
            case "aadhar_back":
                setAadharBack(e.target.files[0]);
                break;
            case "aadhar_front":
                setAadharFront(e.target.files[0]);
                break;
            default:
                console.log("Choose wrong")
        }
    }

    useEffect(() => {
        setData(datum)
    }, [datum])

    function handleChanges(e) {
        let nums = ['mobile', 'total_amount', 'down_payment', 'emi_months', 'whatsapp_no', 'alternate_no', 'aadhar_number', 'bank_account_number', 'device_information_imei1', 'device_information_imei2']
        if (nums.includes(e.target.name)) {
            if (!isNaN(e.target.value)) {
                switch (e.target.name) {
                    case "aadhar_number":
                        setData({
                            ...details,
                            aadhar: {
                                ...details.aadhar,
                                number: e.target.value
                            }
                        })
                        break;
                    case "device_information_imei1":
                        setData({
                            ...details,
                            device_information: {
                                ...details.device_information,
                                imei1: e.target.value
                            }
                        })
                        break;
                    case "device_information_imei2":
                        setData({
                            ...details,
                            device_information: {
                                ...details.device_information,
                                imei2: e.target.value
                            }
                        })
                        break;
                    default:
                        setData({
                            ...details,
                            [e.target.name]: e.target.value
                        })
                }
                // setData({
                //     ...details,
                //     [e.target.name]: e.target.value
                // })
            }
        } else {
            switch (e.target.name) {
                case "pan_card_number":
                    setData({
                        ...details,
                        pan_card: {
                            ...details.pan_card,
                            number: e.target.value
                        }
                    })
                    break;
                case "device_information_device_model":
                    setData({
                        ...details,
                        device_information: {
                            ...details.device_information,
                            device_model: e.target.value
                        }
                    })
                    break;
                case "device_information_device_name":
                    setData({
                        ...details,
                        device_information: {
                            ...details.device_information,
                            device_name: e.target.value
                        }
                    })
                    break;
                default:
                    setData({
                        ...details,
                        [e.target.name]: e.target.value
                    })
            }
        }
    }

    async function updateUser() {
        try {
            let formData = new FormData();
            let d = JSON.stringify(details);
            formData.append('data', d);
            if (aadhar_front) {
                formData.append('aadhar_front', aadhar_front);
            }
            if (aadhar_back) {
                formData.append('aadhar_back', aadhar_back);
            }
            if (pancard_photo) {
                formData.append('pancard_photo', pancard_photo);
            }
            if (statement1) {
                formData.append('statement1', statement1);
            }
            if (statement2) {
                formData.append('statement2', statement2);
            }
            if (sealed_box_photo) {
                formData.append('sealed_box_photo', sealed_box_photo);
            }
            if (sealed_box_back) {
                formData.append('sealed_box_back', sealed_box_back);
            }
            if (signature) {
                formData.append('signature', signature);
            }
            if (bill_photo) {
                formData.append('bill_photo', bill_photo);
            }
            if (customer_recieved_photo) {
                formData.append('customer_recieved_photo', customer_recieved_photo);
            }

            const { data } = await patchRequest(APIS.UPDATE_USER  + id, formData);
            if (data.status) {
                toaster(data.message, true);
                nav(-1)
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false)
        }
    }

    return (

        <div className='row'>
            <div className='col-md-6'>
                <Input placeholder='Enter customer name' onChange={handleChanges} value={details?.customer_name} name="customer_name" />
            </div>
            <div className='col-md-6'>
                <Input placeholder='Enter customer phone' onChange={handleChanges} value={details?.mobile} name="mobile" />
            </div>

            <div className='col-md-6'>
                <Input placeholder='Enter total amount' onChange={handleChanges} value={details?.total_amount} name="total_amount" />
            </div>

            <div className='col-md-6'>
                <Input placeholder='Enter down payment' onChange={handleChanges} value={details?.down_payment} name="down_payment" />
            </div>

            <div className='col-md-6'>
                <Input placeholder='Enter EMI months' onChange={handleChanges} value={details?.emi_months} name="emi_months" />
            </div>

            <div className='col-md-6'>
                <Input placeholder='Enter care of name' onChange={handleChanges} value={details?.care_of} name="care_of" />
            </div>

            <div className='col-md-6'>
                <Input placeholder='Enter whatsapp number' onChange={handleChanges} value={details?.whatsapp_no} name="whatsapp_no" />
            </div>

            <div className='col-md-6'>
                <Input placeholder='Enter alternate number' onChange={handleChanges} value={details?.alternate_no} name="alternate_no" />
            </div>

            <div className='col-md-6'>
                <Input placeholder='Enter occupation' onChange={handleChanges} value={details?.occupation} name="occupation" />
            </div>

            <div className='col-md-6'>
                <Input placeholder='Enter address' onChange={handleChanges} value={details?.address} name="address" />
            </div>

            <h5 className='mt-3 mb-0'>Aadhar Details</h5>

            <div className='col-md-6'>
                <Input placeholder='Enter aadhar number' onChange={handleChanges} value={details?.aadhar?.number} name="aadhar_number" />
            </div>

            <div className='col-md-6'>
                <Input onChange={handleFile} name="aadhar_front" type={"file"} label="Front Image" />
            </div>

            <div className='col-md-6'>
                <Input onChange={handleFile} name="aadhar_back" type={"file"} label="Back Image" />
            </div>

            <h5 className='mt-3 mb-0'>Pancard Details</h5>

            <div className='col-md-6'>
                <Input placeholder='Enter pan card number' onChange={handleChanges} value={details?.pan_card?.number} name="pan_card_number" />
            </div>

            <div className='col-md-6'>
                <Input onChange={handleFile} name="pancard_photo" type={"file"} label="Pancard Photo" />
            </div>

            <h5 className='mt-3 mb-0'>Bank Account Details</h5>

            <div className='col-md-6'>
                <Input placeholder='Enter bank ac number' onChange={handleChanges} value={details?.bank_account_number} name="bank_account_number" />
            </div>

            <div className='col-md-6'>
                <Input placeholder='Enter IFSC code' onChange={handleChanges} value={details?.bank_ifsc_code} name="bank_ifsc_code" />
            </div>

            <div className='col-md-6'>
                <Input onChange={handleFile} name="statement1" type={"file"} label="Statement Front Photo" />
            </div>

            <div className='col-md-6'>
                <Input onChange={handleFile} name="statement2" type={"file"} label="Statement Back Photo" />
            </div>

            <h5 className='mt-3 mb-0'>Device Information</h5>

            <div className='col-md-6'>
                <Input placeholder='Enter IMEI number 1' onChange={handleChanges} value={details?.device_information?.imei1} name="device_information_imei1" />
            </div>

            <div className='col-md-6'>
                <Input placeholder='Enter IMEI number 2' onChange={handleChanges} value={details?.device_information?.imei2} name="device_information_imei2" />
            </div>

            <div className='col-md-6'>
                <Input placeholder='Enter device model' onChange={handleChanges} value={details?.device_information?.device_model} name="device_information_device_model" />
            </div>

            <div className='col-md-6'>
                <Input placeholder='Enter device name' onChange={handleChanges} value={details?.device_information?.device_name} name="device_information_device_name" />
            </div>

            <h5 className='mt-3 mb-0'>Other Documents</h5>

            <div className='col-md-6'>
                <Input onChange={handleFile} name="sealed_box_photo" type={"file"} label="Sealed Box Photo (Front)" />
            </div>

            <div className='col-md-6'>
                <Input onChange={handleFile} name="sealed_box_back" type={"file"} label="Sealed Box Photo (Back)" />
            </div>

            <div className='col-md-6'>
                <Input onChange={handleFile} name="signature" type={"file"} label="Signature" />
            </div>

            <div className='col-md-6'>
                <Input onChange={handleFile} name="bill_photo" type={"file"} label="Bill Photo" />
            </div>

            <div className='col-md-6'>
                <Input onChange={handleFile} name="customer_recieved_photo" type={"file"} label="Customer Recieved Photo" />
            </div>

            <div className='col-md-6 mt-4'>
                <button className='btn btn-success' onClick={updateUser}>
                    Save Details
                </button>
            </div>
        </div>
    )
}

export default Editor