import React, { useEffect, useState } from 'react'
import Button from '../../common/Button'
import Input from '../../common/Input'
import { useNavigate } from 'react-router-dom'

const ShopForm = (props) => {
    const [data, setData] = useState({
        shop_name: "",
        shop_address: "",
        owner_name: ""
    });

    const [shop_image, setShopImage] = useState(null)
    const [shop_bill, setShopBill] = useState(null)
    const [shop_trade_lic, setShopTrade] = useState(null)
    const [shop_cheque, setShopCheque] = useState(null)
    const [owner_pancard, setPancard] = useState(null)

    const nav = useNavigate()
    function handleChanges(e) {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    function handleFiles(e) {
        if (e.target.name === "shop_image") {
            setShopImage(e.target.files[0])
        }
        if (e.target.name === "shop_bill") {
            setShopBill(e.target.files[0])
        }
        if (e.target.name === "shop_trade_lic") {
            setShopTrade(e.target.files[0])
        }
        if (e.target.name === "shop_cheque") {
            setShopCheque(e.target.files[0])
        }
        if (e.target.name === "owner_pancard") {
            setPancard(e.target.files[0])
        }
    }

    function submitForm() {
        let formData = new FormData();
        formData.append('shop_name', data.shop_name)
        formData.append('shop_address', data.shop_address)
        formData.append('owner_name', data.owner_name)

        if (shop_image) {
            formData.append('shop_image', shop_image)
        }
        if (shop_bill) {
            formData.append('shop_bill', shop_bill)
        }
        if (shop_trade_lic) {
            formData.append('shop_trade_lic', shop_trade_lic)
        }
        if (shop_cheque) {
            formData.append('shop_cheque', shop_cheque)
        }
        if (owner_pancard) {
            formData.append('owner_pancard', owner_pancard)
        }

        return formData

    }

    useEffect(() => {
        const { shop_name, shop_address, owner_name } = props
        setData({ shop_name, shop_address, owner_name })
    }, [props])

    return (
        <>
            <div className="top_bar row align-items-center mt-3">
                <div className="col-lg-6 d-flex align-items-center">
                    <Button text="←" className="btn" onClick={() => nav(-1)} />
                    <div className="pagetitle">
                        {props.title} <br />
                    </div>
                </div>
            </div>
            <div className='mt-3'>
                <div className="row">
                    <div className='col-md-6'>
                        <Input placeholder='Enter shop name' onChange={handleChanges} value={data.shop_name} name="shop_name" />
                    </div>
                    <div className='col-md-6'>
                        <Input placeholder='Enter shop address' onChange={handleChanges} value={data.shop_address} name="shop_address" />
                    </div>

                    <div className='col-md-6'>
                        <Input placeholder='Enter owner name' onChange={handleChanges} value={data.owner_name} name="owner_name" />
                    </div>

                    <h4 className='mt-4'>Documents</h4>

                    <div className='col-md-6 mb-3'>
                        <Input label="Shop Image" type="file" onChange={handleFiles} name="shop_image" />
                    </div>

                    <div className='col-md-6 mb-3'>
                        <Input label="Shop Bill" type="file" onChange={handleFiles} name="shop_bill" />
                    </div>

                    <div className='col-md-6 mb-3'>
                        <Input label="Shop Trade License" type="file" onChange={handleFiles} name="shop_trade_lic" />
                    </div>

                    <div className='col-md-6 mb-3'>
                        <Input label="Shop Cheque" type="file" onChange={handleFiles} name="shop_cheque" />
                    </div>

                    <div className='col-md-6 mb-3'>
                        <Input label="Owner Pancard" type="file" onChange={handleFiles} name="owner_pancard" />
                    </div>

                    <div className='col-md-6 mt-4'>
                        <Button type="text" className="btn btn-success" onClick={() => props.onClick(submitForm())} text="Submit" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShopForm