import React, { useEffect, useState } from 'react'
import Button from '../../common/Button'
import Input from '../../common/Input'
import { useNavigate } from 'react-router-dom'
import Dropdown from '../../common/Dropdown'
import { getRequest } from '../../apis/functions'
import APIS from '../../apis/routes'
import { toaster } from '../../apis/commonFunctions'

const SalesmanForm = (props) => {
    const [data, setData] = useState({
        email: "",
        name: "",
        shop: "",
        phone: "",
        password: ""
    });

    const [title, setTitle] = useState("")
    const [shopTitle, setShopTitle] = useState("")
    const [selectedShop, setShopSelection] = useState("")

    const nav = useNavigate()
    function handleChanges(e) {
        if (e.target.name !== "phone") {
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
            if (e.target.name === "shop") {
                let filtered = shops.filter(item => item._id.toString() === e.target.value.toString())
                setShopTitle(filtered[0].shop_name)
                setShopSelection(e.target.value.toString())
                fetchRetailers(e.target.value.toString())
            }
            if (e.target.name === "retailerId") {
                let filtered = retailers.filter(item => item._id.toString() === e.target.value.toString())
                setTitle(filtered[0].name)
            }
        } else {
            if (!isNaN(e.target.value)) {
                setData({
                    ...data,
                    [e.target.name]: e.target.value.trim()
                })
            }
        }
    }

    const [retailers, setRetailers] = useState([])
    const [shops, setShops] = useState([])


    async function fetchRetailers(selectedShop = "") {
        try {
            if (selectedShop) {
                const { data: info } = await getRequest(APIS.LIST + "retailers/" + selectedShop);
                if (info.status) {
                    setRetailers([...info.data.map(item => { return { ...item, shop_name: item.name } })])
                    let filtered = info.data.filter(item => item._id === data.retailerId)
                    if (filtered.length > 0) {
                        setTitle(filtered[0].name)
                    } else {
                        setTitle("Please choose a Retailer")
                    }
                } else {
                    toaster(data.message, false);
                }
            }
        } catch (err) {
            toaster(err, false)
        }
    }

    async function fetchShops() {
        try {
            const { data } = await getRequest(APIS.LIST + "shops");
            if (data.status) {
                setShops(data.data)
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false)
        }
    }

    useEffect(() => {
        const { email, password, name, retailer, shop, phone } = props
        setData({ email, password, name, retailerId: retailer, retailer, phone, shop })
        // let filtered = retailers.filter(item => item._id.toString() === data?.retailer?.toString())
        // let filteredshop = shops.filter(item => item._id.toString() === data?.shop?.toString())
        // if (filteredshop.length > 0) {
        //     setShopTitle(filtered[0].shop_name)
        // } else {
        //     setShopTitle("Please choose a Shop")
        // }
        // if (filtered.length > 0) {
        //     setTitle(filtered[0].name)
        // } else {
        //     setTitle("Please choose a Retailer")
        // }
        fetchRetailers(shop)
        fetchShops()
    }, [props])

    useEffect(() => {
        let filtered = retailers.filter(item => item._id.toString() === data?.retailer?.toString())
        if (filtered.length > 0) {
            setTitle(filtered[0].name)
        } else {
            setTitle("Please choose a Retailer")
        }
    }, [retailers])

    useEffect(() => {
        let filtered = shops.filter(item => item._id.toString() === data?.shop?.toString())
        if (filtered.length > 0) {
            setShopTitle(filtered[0].shop_name)
        } else {
            setShopTitle("Please choose a Shop")
        }
    }, [shops])

    return (
        <>
            <div className="top_bar row align-items-center mt-3">
                <div className="col-lg-6 d-flex align-items-center">
                    <Button text="←" className="btn" onClick={() => nav(-1)} />
                    <div className="pagetitle">
                        {props.title} <br />
                    </div>
                </div>
            </div>
            <div className='mt-3'>
                <div className="row">
                    <div className='col-md-6'>
                        <Input placeholder='Enter name' onChange={handleChanges} value={data.name} name="name" />
                    </div>
                    <div className='col-md-6'>
                        <Input type="email" placeholder='Enter email' onChange={handleChanges} value={data.email} name="email" />
                    </div>

                    {<div className='col-md-6'>
                        <Input placeholder='Enter password' onChange={handleChanges} value={data.password} name="password" />
                    </div>}

                    <div className='col-md-6'>
                        <Input placeholder='Enter phone' onChange={handleChanges} value={data.phone} name="phone" />
                    </div>

                    <div className='col-md-6'>
                        <Dropdown options={shops} title={shopTitle} onSelect={(id) => handleChanges({ target: { name: "shop", value: id } })} />
                    </div>

                    {retailers.length > 0 && <div className='col-md-6'>
                        <Dropdown options={retailers} title={title} onSelect={(id) => handleChanges({ target: { name: "retailerId", value: id } })} />
                    </div>}


                    <div className='col-md-6 mt-4'>
                        <Button type="text" className="btn btn-success" onClick={() => props.onClick(data)} text="Submit" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SalesmanForm