import React from 'react'

const ConfirmationPopup = ({onYes,message}) => {
    return (
        <div className="modal fade" id="decline_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-sm modal-dialog-centered">
                <div className="modal-content">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div className="modal-body text-center">
                        {/* <div className="modal_icon">
                            <Cancel />
                        </div> */}
                        <div className="modal_msg">
                            {message}
                        </div>
                        <div className="modal_btns ">
                            <button type='button' onClick={onYes} data-bs-dismiss="modal">Yes</button>
                            <button className='purple' data-bs-dismiss="modal">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationPopup