import React from 'react'

const Image = ({ src, placeholder ,...props }) => {
    function handleError(e) {
        e.target.src = placeholder ? placeholder : "/user.jpg"
    }
    return (
        <img src={src} {...props} onError={handleError} />
    )
}

export default Image