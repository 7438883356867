import React from 'react'
import ReactPaginate from 'react-paginate';
import { Next, Previous } from './Icons';

const Pagination = ({ handlePageClick, totalCount = 0, limit = 0 }) => {
    let pageCount = Math.ceil(totalCount / limit);

    return (
        pageCount > 1 ? <div className='w-100'>
            <div className='m-auto' style={{ width: "fit-content" }}>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel={<Next />}
                    className="pagination"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={limit}
                    pageCount={pageCount}
                    previousLabel={<Previous />}
                    renderOnZeroPageCount={null}
                />
            </div>
        </div> : null
    )
}

export default Pagination