import React from 'react'
import Image from './Image'

export default function Table_profile({ name, image }) {
    return (
        <>
            <div className="table_profile">
                <Image src={image ? image : "/user.jpg"} alt="" className='img-fluid' />
                <div className="tname">{name}</div>
            </div>
        </>
    )
}
