import React, { useState } from 'react'
import ShopForm from './ShopForm'
import { toaster } from '../../apis/commonFunctions';
import { postRequest } from '../../apis/functions';
import APIS from '../../apis/routes'
import { useNavigate } from 'react-router-dom';

const AddShop = () => {
    const [data, setData] = useState({
        finance_name: "",
        interest: "",
        fee: ""
    });

    const nav = useNavigate()

    async function addFinance(obj) {
        console.log(Object.fromEntries(obj))
        try {
            const { data } = await postRequest(APIS.SHOP, obj);
            if (data.status) {
                toaster(data.message, true);
                nav(-1)
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false)
        }
    }

    return (
        <ShopForm title="Add Shop" shop_name={data.shop_name} shop_address={data.shop_address} owner_name={data.owner_name} onClick={(obj) => addFinance(obj)} />
    )
}

export default AddShop