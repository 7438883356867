import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "",
    email: "",
    profile_pic: "",
    read: true,
    _id: ""
}

export const detailsSlice = createSlice({
    name: 'details',
    initialState: initialState,
    reducers: {
        setDetails: (state, { payload }) => {
            state.name = payload.name
            state.email = payload.email
            state.profile_pic = payload.profile_pic
            state._id = payload._id
            state.read = payload.read
        },
        markReadSlice: (state, { payload }) => {
            state.read = payload.read
        }
    }
})

export const { setDetails, markReadSlice } = detailsSlice.actions

export default detailsSlice.reducer;