import { createSlice } from "@reduxjs/toolkit";

const initialState = [{ value: "USERMANAGEMENT", label: "User Access Control" }, { value: "PROPERTYMANAGEMENT", label: "Property Listing Management" }, { value: "BIDMANAGEMENT", label: "Auction and Bidding Management" }, { value: "CONTENTMANAGEMENT", label: "User Support and Assistance" }]

export const detailsSlice = createSlice({
    name: 'privillages',
    initialState: initialState,
    reducers: {
        setPrivillages: (state, { payload }) => {
            return initialState
        }
    }
})

export const { setPrivillages } = detailsSlice.actions

export default detailsSlice.reducer;