import React, { useState } from 'react'
import Dropdown from '../Dropdown'

const StatusPopup = ({ onUpdate, message }) => {

    const [status, setStatus] = useState("Paid")

    return (
        <div className="modal fade" id="decline_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-sm modal-dialog-centered">
                <div className="modal-content">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div className="modal-body text-center">
                        {/* <div className="modal_icon">
                            <Cancel />
                        </div> */}
                        <div className="modal_msg">
                        {message}
                            <Dropdown onSelect={(s) => setStatus(s)} title={status} options={[{ shop_name: "Paid", _id: "Paid" }, { shop_name: "Pending", _id: "Pending" }, { shop_name: "Failed", _id: "Failed" }]} />
                        </div>
                        <div className="modal_btns ">
                            <button type='button' onClick={() => { onUpdate(status) }} data-bs-dismiss="modal">Update</button>
                            <button className='purple' data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatusPopup