import React, { useEffect, useState } from 'react'
import { Block, Calculator, Delete, Eye, Pencil, Lock, Unlock, Search } from '../../common/Icons'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { toaster } from '../../apis/commonFunctions'
import { deleteRequest, getRequest, patchRequest } from '../../apis/functions'
import APIS from '../../apis/routes'
import NoDataRow from '../../common/NoDataRow'
import Button from '../../common/Button'
import Switch from "react-switch";
import ConfirmationPopup from '../../common/popups/ConfirmationPopup'
import Pagination from '../../common/Pagination'
import Search_input from '../../common/Search_input'
import moment from 'moment/moment'


export default function UserManagement() {
    const [all, setAll] = useState([]);
    const router = useNavigate()
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(8);
    const [mainid, setMainId] = useState("");
    const [total, setTotal] = useState(0)
    const [params] = useSearchParams();
    // console.log(Array.from(params.entries())," thishsishihsih")
    const search = params.get('search')


    const fetchData = async () => {
        try {
            const result = await getRequest(`${APIS.USER}?search=${search ?? ""}&skip=${skip}&limit=${limit}`);
            if (!result.data.status) {
                toaster(result.data.message, false)
            } else {
                setAll(result.data.data)
                setTotal(result.data.total_count)

            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    function handlePageClick({ selected }) {
        setSkip(selected * limit)
    }

    const deleteFinance = async (id) => {
        try {
            const { data } = await deleteRequest(`${APIS.USER}/${mainid}`);
            if (!data.status) {
                toaster(data.message, false)
            } else {
                toaster(data.message, true)
                fetchData()
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    const lockUnlock = async (id, status) => {
        try {
            const { data } = await patchRequest(`${APIS.LOCK_UNLOCK}${id}`, { phone_status: status });
            if (!data.status) {
                toaster(data.message, false)
            } else {
                toaster(data.message, true)
                fetchData()
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [search, skip])

    return (
        <>
            <div className="top_bar row align-items-center mt-3">
                <div className="col-lg-6">
                    <div className="pagetitle">
                        User Management <br />
                    </div>
                </div>
                <div className="col-lg-6 text-end">
                    {/* <Button text="Add Retailer" className="btn btn-success" onClick={() => nav("add")} /> */}
                    <Search_input placeholder={'Search here'} search={search} handleSearch={(e) => router('?search=' + (e.nativeEvent.data === " " ? search + "+" : e.target.value))} icon={<Search />} className='search_input' />
                </div>
            </div>
            <div className="row mt-5">
                <div className="table-responsive">
                    <table className='table table-lg'>
                        <thead className='table-light'>
                            <tr colSpan="4">
                                <th>S.No</th>
                                <th>Name</th>
                                <th>Aadhar No.</th>
                                <th>Mobile No.</th>
                                <th>Unique Id</th>
                                <th>Status</th>
                                <th>Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {all.length > 0
                                ?
                                all.map((element, index) => (
                                    <tr key={element._id}>
                                        <td>{index + 1}</td>
                                        <td style={{ textTransform: "inherit" }}>{element.customer_name}</td>
                                        <td style={{ textTransform: "unset" }}>{element.loginid}</td>
                                        <td style={{ textTransform: "unset" }}>{element.mobile}</td>
                                        <td style={{ textTransform: "unset" }}>{element.unique_id}</td>
                                        <td style={{ textTransform: "unset" }}>{element?.phone_status === 1 ? "Locked" : "Unlocked"}</td>
                                        <td style={{ textTransform: "unset" }}>{moment(element.updatedAt).format("DD MMM YYYY")}</td>
                                        <td>
                                            <div className="actions">
                                                <button className='view p-2'>
                                                    <Link className='view' to={'/user-management/' + element._id}>
                                                        <Eye />
                                                    </Link>
                                                </button>
                                                <button className='view p-2'>
                                                    <Link className='view' to={'/user-emi-management/' + element._id}>
                                                        <Calculator />
                                                    </Link>
                                                </button>
                                                <button className='block' onClick={() => { lockUnlock(element._id, element.phone_status === 1 ? 2 : 1) }} style={{ color: (element.admin_blocked ? "#0F993E" : "#ff4c27") }}>
                                                    {element.phone_status === 1 ? <Unlock /> : <Lock />}
                                                </button>
                                                <button className='delete' onClick={() => setMainId(element._id)} data-bs-toggle="modal" data-bs-target="#decline_modal">
                                                    <Delete />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                : <NoDataRow />}
                            {total > limit && <tr className='text-center'><td colSpan={20}><Pagination handlePageClick={handlePageClick} totalCount={total} limit={limit} /></td></tr>}

                        </tbody>
                    </table>
                </div>
            </div>
            <ConfirmationPopup message={"Do you really want to delete this user ?"} onYes={() => deleteFinance()} />
        </>
    )
}
