import React, { useEffect, useState } from 'react'
import Button from '../../common/Button'
import Input from '../../common/Input';
import { useNavigate } from 'react-router-dom'
import { toaster } from '../../apis/commonFunctions';
import { getRequest, patchRequest } from '../../apis/functions';
import APIS from '../../apis/routes'

const Settings = () => {
    const nav = useNavigate();
    const [fine, setFine] = useState(0)
    const [coupon_amount, setCoupon] = useState(0);
    const getSettings = async () => {
        try {
            const { data } = await getRequest(APIS.SETTINGS);
            if (!data.status) {
                if (data.code === 203) {
                    localStorage.clear()
                    nav('/login')
                    toaster(data.message, false)
                }
            } else {
                setFine(data.data.fine)
                setCoupon(data.data.coupon_amount);
            }
        } catch (e) {
            toaster(e.message, false);
        }
    }

    const submitForm = async () => {
        try {
            const { data } = await patchRequest(APIS.SETTINGS, { fine, coupon_amount });
            if (!data.status) {
                if (data.code === 203) {
                    localStorage.clear()
                    nav('/login')
                }
                toaster(data.message, false)
            } else {
                toaster(data.message, true)
                getSettings()
            }
        } catch (e) {
            toaster(e.message, false);
        }
    }

    useEffect(() => {
        getSettings()
    }, [])

    return (
        <>
            <div className="top_bar row align-items-center mt-3">
                <div className="col-lg-6 d-flex align-items-center">
                    <Button text="←" className="btn" onClick={() => nav(-1)} />
                    <div className="pagetitle">
                        {"Settings"} <br />
                    </div>
                </div>
            </div>
            <div className='mt-3'>
                <div className="row">
                    <div className='col-md-6'>
                        <Input label={"Enter fine amount"} placeholder='Enter fine amount' onChange={(e) => setFine(e.target.value)} value={fine} name="fine" />
                    </div>
                    <div className='col-md-6'>
                        <Input label={"Enter coupon amount"} placeholder='Enter coupon amount' onChange={(e) => setCoupon(e.target.value)} value={coupon_amount} name="coupon_amount" />
                    </div>

                    <div className='col-md-6 mt-4'>
                        <Button type="text" className="btn btn-success" onClick={() => submitForm()} text="Submit" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Settings