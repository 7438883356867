import React from 'react'
import ImageViewer from '../ImageViewer'

const Image = ({ src }) => {
    return (
        <div className="modal" id="image-opener" tabindex="-1" aria-labelledby="image-openerLabel" aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center">
                        <ImageViewer src={process.env.REACT_APP_IMAGE_URL + src} />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Image