import React, { useEffect, useState } from 'react'
import EditProfile from '../../common/EditProfile'
import { toaster } from '../../apis/commonFunctions'
import { getRequest, patchRequest } from '../../apis/functions'
import APIS from '../../apis/routes'
import { useDispatch } from 'react-redux'
import { setDetails } from '../../redux/slices/detailsSlice'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import Image from '../../common/Image'

export default function AdminProfile() {
    const [userDetails, setData] = useState({})
    const [load, setLoad] = useState(false)
    const nav = useNavigate()

    const [toggle, setToggle] = useState(false);
    const dispatcher = useDispatch()

    const getProfile = async () => {
        try {
            const result = await getRequest(APIS.GET_ADMIN_PROFILE);
            if (!result.data.status) {
                if (result.data.code === 203) {
                    localStorage.clear()
                    nav('/login')
                    toast.info(result.data.message)
                }
            } else {
                setData(result.data.data)
                dispatcher(setDetails(result.data.data))
                localStorage.setItem('info', JSON.stringify(result.data.data))
                setLoad(false)
            }
        } catch (err) {
            toast.error(err.message);
        }
    }


    async function updateProfile(details, file) {
        try {
            let formData = new FormData();
            formData.append('name', details.name);
            formData.append('email', details.email);
            formData.append('withdraw_days', details.withdraw_days);
            if (file) {
                formData.append('profile_pic', file);
            }
            const { data } = await patchRequest(APIS.UPDATE_PROFILE, formData);
            if (data.status) {
                toaster(data.message, true);
                localStorage.setItem('name', data.data.name);
                localStorage.setItem('email', data.data.email);
                localStorage.setItem('profile_pic', data?.data?.profile_pic);
                localStorage.setItem('withdraw_days', data.data.withdraw_days);
                dispatcher(setDetails(data.data))
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }


    useEffect(() => {
        getProfile()
    }, []);
    return (
        <>
            <div className="outer_div mt-4">
                {!toggle ?
                    <div className="row">
                        <div className="col-lg-2 col-md-2">
                            <div className="profile_image">
                                <Image src={userDetails.profile_pic !== "" ? userDetails.profile_pic : "/user.jpg"} alt="" className="rounded-circle img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-10 col-md-10">
                            <ul className="profile_list p-0">
                                <div className="col-md-6"><li >
                                    <div className="detail_wrapper">
                                        <div className="small_heading">Name</div>
                                        <div className="name mt-2">{userDetails.name}</div>
                                    </div>
                                </li></div>
                                <div className="col-md-6"><li >
                                    <div className="detail_wrapper">
                                        <div className="small_heading">Email Address</div>
                                        <div className="name mt-2 text-lowercase">{userDetails.email}</div>
                                    </div>
                                </li></div>
                            </ul>
                        </div>
                    </div>
                    : <EditProfile firstname={userDetails.firstname} lastname={userDetails.lastname} email={userDetails.email} profile_pic={userDetails.profile_pic} onUpdateProfile={updateProfile} />}<div className="card_botom">
                </div>
            </div>
        </>
    )
}