import React, { useEffect, useState } from 'react'
import Button from '../../common/Button'
import Input from '../../common/Input'
import { useNavigate } from 'react-router-dom'
import { toaster } from '../../apis/commonFunctions'

const AddFinance = (props) => {
    const [data, setData] = useState({
        finance_name: "",
        interest: "",
        fee: ""
    });
    const [file, setFile] = useState(null)
    const nav = useNavigate()
    function handleChanges(e) {
        if (e.target.name === "finance_name") {
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
        } else {
            if (!isNaN(e.target.value)) {
                setData({
                    ...data,
                    [e.target.name]: e.target.value.trim()
                })
            }
        }
    }

    function getForm() {
        let formData = new FormData();
        formData.append('finance_name', data.finance_name)
        formData.append('interest', data.interest)
        formData.append('fee', data.fee)
        if (file) {
            formData.append('image', file)
        }
        return formData
    }

    function handleFile(e) {
        let file = e.target.files[0]
        let type = file?.type?.split("/")[0] ?? ""
        if (type === "image") {
            setFile(file)
        } else {
            toaster("Please choose a valid image", false)
        }
    }

    useEffect(() => {
        const { finance_name, interest, fee } = props
        setData({ finance_name, interest, fee })
    }, [props])

    return (
        <>
            <div className="top_bar row align-items-center mt-3">
                <div className="col-lg-6 d-flex align-items-center">
                    <Button text="←" className="btn" onClick={() => nav(-1)} />
                    <div className="pagetitle">
                        {props.title} <br />
                    </div>
                </div>
            </div>
            <div className='mt-3'>
                <div className="row">
                    <div className='col-md-6'>
                        <Input placeholder='Enter finance name' onChange={handleChanges} value={data.finance_name} name="finance_name" />
                    </div>
                    <div className='col-md-6'>
                        <Input placeholder='Enter interest' onChange={handleChanges} value={data.interest} name="interest" />
                    </div>

                    <div className='col-md-6'>
                        <Input placeholder='Enter fee' onChange={handleChanges} value={data.fee} name="fee" />
                    </div>

                    <div className='col-md-6'>
                        <Input onChange={handleFile} type={"file"} />
                    </div>

                    <div className='col-md-6 mt-4'>
                        <Button type="text" className="btn btn-success" onClick={() => props.onClick(getForm())} text="Submit" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddFinance