import './App.css';
import './Mobile.css'
import { Route, Routes, useNavigate } from 'react-router-dom';
import Protected from './guards/Protected';
import Authentic from './guards/Authentic';
import Login_Signup from './components/admin/Login_Signup';
import ForgotPassword from './components/admin/ForgotPassword';
import Dashboard from './components/home/Dashboard';
import PrivacyPolicy from './components/content/PrivacyPolicy';
import TermsCondition from './components/content/TermsCondition';
import FinanceManagement from './components/finance/FinanceManagement';
import RequestManagement from './components/requests/RequestManagement';
import AddFinance from './components/finance/AddFinance';
import UpdateFinance from './components/finance/UpdateFinance';
import AdminProfile from './components/admin/AdminProfile';
import ChangePassword from './components/admin/ChangePassword';
import PageNotFound from './common/PageNotFound';
import { useDispatch } from 'react-redux';
import { getRequest, setRouter } from './apis/functions';
import APIS from './apis/routes';
import { useEffect } from 'react';
import { setDetails } from './redux/slices/detailsSlice';
import { toaster } from './apis/commonFunctions';
import RetailerManagement from './components/retailer/RetailerManagement';
import AddRetailer from './components/retailer/AddRetailer';
import UpdateRetailer from './components/retailer/UpdateRetailer';
import ShopManagement from './components/shop/ShopManagement';
import AddShop from './components/shop/AddShop';
import UpdateShop from './components/shop/UpdateShop';
import ShopDetails from './components/shop/ShopDetails';
import RequestDetails from './components/requests/RequestDetails';
import SalesmanManagement from './components/salesman/SalesmanManagement';
import AddSalesman from './components/salesman/AddSalesman';
import UpdateSalesman from './components/salesman/UpdateSalesman';
import UserManagement from './components/users/UserManagement';
import UserDetails from './components/users/UserDetails';
import EmiManagement from './components/emis/EmiManagement';
import UserEMIs from './components/emis/UserEMIs';
import Settings from './components/admin/Settings';
import jsPDF from 'jspdf';
import Chats from './components/chat/Chats';


function App() {
  const router = useNavigate();
  const dispatcher = useDispatch()
  setRouter(router);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await getRequest(APIS.GET_ADMIN_PROFILE);
        let info = JSON.parse(localStorage.getItem('info'))
        info.read = data.data.read
        localStorage.setItem('info', JSON.stringify(info))
        if (data.status) {
          dispatcher(setDetails(data.data))
        }
        router("/login")
      } catch (err) {
        // toaster(err.message, false);
      }
    })()
    // generatePDF()
  }, [])

  return (
    <>
      {/* <Login_Signup/> */}
      <Routes>
        <Route path='/login' element={<Authentic><Login_Signup /></Authentic>} />
        <Route path='/forgot-password' element={<Authentic><ForgotPassword /></Authentic>} />
        <Route path='/' element={<Protected />}>
          <Route exact path='/' element={<Dashboard />} />
          <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route exact path='/terms-and-condition' element={<TermsCondition />} />

          <Route exact path='/chats' element={<Chats />} />

          <Route exact path='/finance-management' element={<FinanceManagement />} />
          <Route exact path='/finance-management/add' element={<AddFinance />} />
          <Route exact path='/finance-management/:id' element={<UpdateFinance />} />

          <Route exact path='/shop-management' element={<ShopManagement />} />
          <Route exact path='/shop-management/add' element={<AddShop />} />
          <Route exact path='/shop-management/edit/:id' element={<UpdateShop />} />
          <Route exact path='/shop-management/:id' element={<ShopDetails />} />

          <Route exact path='/request-management' element={<RequestManagement />} />
          <Route exact path='/request-management/add' element={<AddFinance />} />
          <Route exact path='/request-management/:id' element={<RequestDetails />} />

          <Route exact path='/retailer-management' element={<RetailerManagement />} />
          <Route exact path='/retailer-management/add' element={<AddRetailer />} />
          <Route exact path='/retailer-management/:id' element={<UpdateRetailer />} />

          <Route exact path='/salesman-management' element={<SalesmanManagement />} />
          <Route exact path='/salesman-management/add' element={<AddSalesman />} />
          <Route exact path='/salesman-management/:id' element={<UpdateSalesman />} />

          <Route exact path='/user-management' element={<UserManagement />} />
          <Route exact path='/emi-management' element={<EmiManagement />} />
          <Route exact path='/user-emi-management/:id' element={<UserEMIs />} />
          {/* <Route exact path='/user-management/add' element={<AddSalesman />} /> */}
          <Route exact path='/user-management/:id' element={<UserDetails />} />

          <Route exact path='/admin/profile' element={<AdminProfile />} />
          <Route exact path='/admin/change-password' element={<ChangePassword />} />
          <Route exact path='/settings' element={<Settings />} />
          {/* <Route exact path='/provider-management' element={<ProviderManagement />} /> */}
        </Route>
        <Route exact path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;