import React, { useEffect, useState } from 'react'
import { toaster } from '../../apis/commonFunctions';
import { getRequest, patchRequest } from '../../apis/functions';
import APIS from '../../apis/routes'
import { useNavigate, useParams } from 'react-router-dom';
import RetailerForm from './RetailerForm';

const UpdateRetailer = () => {
    const [data, setData] = useState({
        email: "",
        name: "",
        shop : "",
        phone : ""
    });

    

    // useEffect(() => {
    //     fetchShops()
    // }, []);

    const { id } = useParams();

    const nav = useNavigate()

    async function updateFinance(obj) {
        try {
            const { data } = await patchRequest(APIS.RETAILER + "/" + id, obj);
            if (data.status) {
                toaster(data.message, true);
                nav(-1)
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false)
        }
    }

    async function fetchFinance() {
        try {
            const { data } = await getRequest(APIS.RETAILER + "/" + id);
            if (data.status) {
                setData(data.data)
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false)
        }
    }

    useEffect(() => {
        fetchFinance()
    }, [id]);

    return (
        <RetailerForm phone={data.phone} shop={data.shop} title="Update Retailer" email={data.email} name={data.name} onClick={(obj) => updateFinance(obj)} />
    )
}

export default UpdateRetailer