import React, { useEffect, useState } from 'react'
import { Cancel, Delete, Eye, Tick } from '../../common/Icons'
import { useNavigate } from 'react-router-dom'
import { toaster } from '../../apis/commonFunctions'
import { deleteRequest, getRequest, patchRequest } from '../../apis/functions'
import APIS from '../../apis/routes'
import NoDataRow from '../../common/NoDataRow'
import ConfirmationPopup from '../../common/popups/ConfirmationPopup'
import LoadingRow from '../../common/LoadingRow'
import Pagination from '../../common/Pagination'


export default function Users() {
    const [all, setAll] = useState([]);
    const nav = useNavigate()
    const [skip, setSkip] = useState(0)
    const [search, setSearch] = useState("");
    const [mainid, setMainId] = useState("");
    const [loader, setLoader] = useState(false);
    const LIMIT = 8

    const fetchData = async () => {
        try {
            setLoader(true)
            const result = await getRequest(`${APIS.REQUEST}?search=${search}&skip=${skip}&limit=${LIMIT}`);
            setLoader(false)
            if (!result.data.status) {
                toaster(result.data.message, false)
            } else {
                setAll(result.data.data)
                setTotal(result.data.total_count)
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    const deleteFunction = async () => {
        try {
            const { data } = await deleteRequest(`${APIS.REQUEST}/${mainid}`);
            if (!data.status) {
                toaster(data.message, false)
            } else {
                toaster(data.message, true)
                setSkip(0)
                fetchData()
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    const acceptReject = async (id, status) => {
        try {
            const { data } = await patchRequest(APIS.REQUEST + "/accept-reject" + "/" + id, { status });
            if (data.status) {
                toaster(data.message, true);
                fetchData();
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false);
        }
    }

    function handlePageClick({ selected }) {
        setSkip(selected * LIMIT)
    }

    const [total, setTotal] = useState(0)

    useEffect(() => {
        fetchData();
    }, [search, skip])

    return (
        <>
            <div className="top_bar row align-items-center mt-3">
                <div className="col-lg-6">
                    <div className="pagetitle">
                        Request Management <br />
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="table-responsive">
                    <table className='table table-lg'>
                        <thead className='table-light'>
                            <tr colSpan="4">
                                <th>S.No</th>
                                <th>Customer Name</th>
                                <th>Salesman Name</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loader ? <LoadingRow /> : all.length > 0
                                ?
                                all.map((element, index) => (
                                    <tr key={element._id}>
                                        <td>{index + 1}</td>
                                        <td>{element.customer_name}</td>
                                        <td>{element.retailer}</td>
                                        <td>{element.status}</td>
                                        {/* <td><Switch onChange={(e) => { toggleSwitch({ ...element, status: e }) }} checked={element.status} />
                                        </td> */}
                                        <td>
                                            <div className="actions">
                                                <button className='block' onClick={() => nav(element._id)} >
                                                    <Eye />
                                                </button>
                                                {element.status === "Pending" && <button className='block' data-bs-toggle="tooltip" data-bs-placement="bottom" title="Accept the request" onClick={() => acceptReject(element._id, "Approved")} >
                                                    <Tick />
                                                </button>}
                                                {element.status === "Pending" && <button data-bs-toggle="tooltip" data-bs-placement="bottom" title="Reject the request" className='block' onClick={() => acceptReject(element._id, "Rejected")} >
                                                    <Cancel />
                                                </button>}
                                                <button className='delete' onClick={() => setMainId(element._id)} data-bs-toggle="modal" data-bs-target="#decline_modal">
                                                    <Delete />
                                                </button>
                                                {/* <Button className="btn btn-success table-btn" onClick={() => acceptReject(element._id, "Approved")} text={<Tick />} style={{ color: "green", fontWeight: "100", padding: "5px 5px" }} />
                                                {element.status === "Pending" && <Button className="btn btn-danger table-btn" onClick={() => acceptReject(element._id, "Rejected")} text={<Cancel />} style={{ color: "red", fontWeight: "100", padding: "5px 5px" }} />} */}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                : <NoDataRow />}
                            {total > LIMIT && <tr className='text-center'><td colSpan={20}><Pagination handlePageClick={handlePageClick} totalCount={total} limit={LIMIT} /></td></tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            <ConfirmationPopup message={"Do you really want to delete this request ?"} onYes={() => deleteFunction()} />
        </>
    )
}
