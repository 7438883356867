import React, { useEffect, useState } from 'react'
import { toaster } from '../../apis/commonFunctions';
import { getRequest, patchRequest } from '../../apis/functions';
import APIS from '../../apis/routes'
import { useNavigate, useParams } from 'react-router-dom';
import SalesmanForm from './SalesmanForm';

const UpdateSalesman = () => {
    const [data, setData] = useState({
        email: "",
        name: "",
        shop: "",
        phone: ""
    });

    const { id } = useParams();

    const nav = useNavigate()

    async function updateSalesman(obj) {
        try {
            if (obj.password && obj.password === "") {
                delete obj.password
            }
            const { data } = await patchRequest(APIS.SALESMAN + "/" + id, obj);
            if (data.status) {
                toaster(data.message, true);
                nav(-1)
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false)
        }
    }

    async function fetchSalesman() {
        try {
            const { data } = await getRequest(APIS.SALESMAN + "/" + id);
            if (data.status) {
                setData(data.data)
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false)
        }
    }

    useEffect(() => {
        fetchSalesman()
    }, [id]);

    return (
        <SalesmanForm phone={data.phone} shop={data.shop} retailer={data.retailerId} title="Update Salesman" email={data.email} name={data.name} onClick={(obj) => updateSalesman(obj)} />
    )
}

export default UpdateSalesman