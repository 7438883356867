import React, { useEffect, useRef, useState } from 'react'
import { Block, Delete, Eye, Pencil, Search } from '../../common/Icons'
import { Link, useNavigate } from 'react-router-dom'
import { toaster } from '../../apis/commonFunctions'
import { deleteRequest, getRequest, patchRequest, postRequest } from '../../apis/functions'
import APIS from '../../apis/routes'
import NoDataRow from '../../common/NoDataRow'
import Button from '../../common/Button'
import Switch from "react-switch";
import ConfirmationPopup from '../../common/popups/ConfirmationPopup'


const Chat = ({ name, subject, message, onClick }) => {
    return (
        <li onClick={onClick}>
            <img src='/user.jpg' className='img-fluid' />
            <div className='grouped'>
                <h5 className='mb-0'>{name}</h5>
                <h6 className='mb-0 subject'>{subject}</h6>
                <p className='ellipses mb-0'>{message}</p>
            </div>
        </li>
    )
}

const LeftMessage = ({ message }) => {
    return (
        <li className='left'>
            <p className='mb-0'>{message}</p>
        </li>
    )
}

const RightMessage = ({ message }) => {
    return (
        <li className='right'>
            <p className='mb-0'>{message}</p>
        </li>
    )
}


export default function Chats() {
    const [chats, setAll] = useState([]);
    const nav = useNavigate()
    const scrollRef = useRef(null);
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(100);
    const [search, setSearch] = useState("");
    const [messages, setMessages] = useState([])
    const [selected, setSelected] = useState({
        _id: "",
        closed: false
    });
    const [message, setMessage] = useState('')

    const fetchData = async () => {
        try {
            const result = await getRequest(`${APIS.CHATS}`);
            if (!result.data.status) {
                toaster(result.data.message, false)
            } else {
                setAll(result.data.data)
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    const scrollToBottom = () => {
        setTimeout(() => {
            if(document.getElementById('message_bar')){
                document.getElementById('message_bar').scrollTop = document.getElementById('message_bar').scrollHeight
            }
        })
    };

    const fetchMessages = async (id) => {
        try {
            const result = await getRequest(`${APIS.MESSAGES}?chatId=${id}`);
            if (!result.data.status) {
                toaster(result.data.message, false)
            } else {
                let array = [...result.data.data]
                setMessages([...array.reverse()])
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    async function sendMessage() {
        try {
            const result = await postRequest(`${APIS.SEND_MESSAGE}`, { chatId: selected._id, message });
            if (!result.data.status) {
                toaster(result.data.message, false)
            } else {
                setMessages([...messages, { sent_by: "ADMIN", message }])
                setMessage('')
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    async function closeChat() {
        try {
            const { data } = await postRequest(`${APIS.CLOSE_CHAT}`, { chatId: selected._id });
            if (!data.status) {
                toaster(data.message, false)
            } else {
                toaster(data.message, true)
                setSelected({
                    ...selected,
                    closed: true
                });
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    };

    useEffect(() => {
        fetchData();
    }, [search])

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <>
            <div className="row mt-4">
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='chats'>
                                <ul>
                                    {chats.map(item => <Chat onClick={() => {
                                        setSelected(item);
                                        fetchMessages(item._id);
                                    }} name={item.name} subject={item.subject} message={item.last_message} />)}
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className='messages'>
                                <div className='container-fluid'>
                                    <div class="chat-container">
                                        {selected._id === "" && <div className='no-chat d-flex align-items-center justify-content-center text-center' style={{ height: "550px" }}>
                                            <div>
                                                <img src='/no.webp' style={{ maxHeight: "300px", }} className='img-fluid' />
                                                <h3>No chat selected yet</h3>
                                            </div>
                                        </div>}
                                        {selected._id !== "" && <>
                                            <header class="chat-header">
                                                <div className='d-flex align-items-center'>
                                                    <img src='/user.jpg' className='img-fluid' />
                                                    <div className='ms-2'>
                                                        <h5 className='mb-0'>{selected.name}</h5>
                                                        <p className='mb-0 chatsubject'>{selected.subject}</p>
                                                    </div>
                                                </div>
                                                {!selected.closed && <>
                                                    <button id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="bi bi-three-dots-vertical"></i></button>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <button class="dropdown-item" onClick={closeChat}>Close Chat</button>
                                                    </div></>}
                                            </header>
                                            {/* <div class="chat-messages" > */}
                                                <ul class="chat-messages" id="message_bar" ref={scrollRef}>
                                                    {messages.map(item => {
                                                        if (item.sent_by === "ADMIN") {
                                                            return <RightMessage message={item.message} />
                                                        } else {
                                                            return <LeftMessage message={item.message} />
                                                        }
                                                    })}
                                                </ul>
                                            {/* </div> */}
                                            <footer class="chat-footer">
                                                {selected.closed && <p className='text-center w-100'>This chat has been closed</p>}
                                                {!selected.closed && <form onSubmit={(e) => e.preventDefault()}>
                                                    <textarea onKeyPress={handleKeyPress} onChange={(e) => setMessage(e.target.value)} class="message-input" placeholder="Type your message..." value={message}>{message}</textarea>
                                                    <button class="send-button" type='submit'>Send</button></form>}
                                            </footer></>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
