import React, { useState } from 'react'
import FinanceForm from './FinanceForm'
import { toaster } from '../../apis/commonFunctions';
import { postRequest } from '../../apis/functions';
import APIS from '../../apis/routes'
import { useNavigate } from 'react-router-dom';

const AddFinance = () => {
    const [data, setData] = useState({
        finance_name: "",
        interest: "",
        fee: ""
    });

    const nav = useNavigate()

    async function addFinance(obj) {
        try {
            const { data } = await postRequest(APIS.FINANCE, obj);
            if (data.status) {
                toaster(data.message, true);
                nav(-1)
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false)
        }
    }

    return (
        <FinanceForm title="Add Finance" finance_name={data.finance_name} interest={data.interest} fee={data.fee} onClick={(obj) => addFinance(obj)} />
    )
}

export default AddFinance