import React from 'react'
import { Navigate } from 'react-router-dom';

const Authentic = (props) => {
    let TOKEN = localStorage['x_token'];
    if (TOKEN) {
        return <Navigate to="/" replace />
    } else {
        return props.children; 
    }
}

export default Authentic