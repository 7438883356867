import React, { useEffect, useState } from 'react'
import RetailerForm from './RetailerForm'
import { toaster } from '../../apis/commonFunctions';
import { getRequest, postRequest } from '../../apis/functions';
import APIS from '../../apis/routes'
import { useNavigate } from 'react-router-dom';

const AddRetailer = () => {
    const [data, setData] = useState({
        email: "",
        password: "",
        name: "",
        shop : "",
        phone : ""
    });

    const [shops, setShops] = useState([])

    const nav = useNavigate()

    async function addRetailer(obj) {
        try {
            const { data } = await postRequest(APIS.RETAILER, obj);
            if (data.status) {
                toaster(data.message, true);
                nav(-1)
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false)
        }
    }

    async function fetchShops() {
        try {
            const { data } = await getRequest(APIS.SHOP);
            if (data.status) {
                setShops(data.data)
                // setData({ ...data, shop: data.data[0]._id })
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false)
        }
    }

    useEffect(() => {
        fetchShops()
    }, []);

    return (
        <RetailerForm passwordExists={true} shops={shops} phone={data.phone} shop={data.shop} title="Add Retailer" email={data.email} password={data.password} name={data.name} onClick={(obj) => addRetailer(obj)} />
    )
}

export default AddRetailer