import React, { useEffect, useState } from 'react'
import FinanceForm from './FinanceForm'
import { toaster } from '../../apis/commonFunctions';
import { getRequest, patchRequest } from '../../apis/functions';
import APIS from '../../apis/routes'
import { useNavigate, useParams } from 'react-router-dom';

const UpdateFinance = () => {
    const [data, setData] = useState({
        finance_name: "",
        interest: "",
        fee: ""
    });

    const { id } = useParams();

    const nav = useNavigate()

    async function updateFinance(obj) {
        try {
            const { data } = await patchRequest(APIS.FINANCE + "/" + id, obj);
            if (data.status) {
                toaster(data.message, true);
                nav(-1)
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false)
        }
    }

    async function fetchFinance() {
        try {
            const { data } = await getRequest(APIS.FINANCE + "/" + id);
            if (data.status) {
                setData(data.data)
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false)
        }
    }

    useEffect(() => {
        fetchFinance()
    }, [id]);

    return (
        <FinanceForm title="Update Finance" finance_name={data.finance_name} interest={data.interest} fee={data.fee} onClick={(obj) => updateFinance(obj)} />
    )
}

export default UpdateFinance