import React, { useEffect, useState } from 'react'
import ImagePopup from '../../common/popups/Image';
import { Link, useParams } from 'react-router-dom';
import { getRequest } from '../../apis/functions';
import APIS from '../../apis/routes';
import { toaster } from '../../apis/commonFunctions';
import Button from '../../common/Button';
import Image from '../../common/Image';
import Editor from './Editor';

const UserDetails = () => {

    const [details, setDetails] = useState({
        emis: []
    });

    const [url, setUrl] = useState("")
    const { id } = useParams();
    const [toggle, setToggle] = useState(false);

    async function fetchDetails() {
        try {
            const { data } = await getRequest(APIS.USER + "/" + id);
            if (data.status) {
                setDetails({ ...details, ...data.data })
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false)
        }
    }

    useEffect(() => {
        fetchDetails()
    }, [id]);

    return (
        <>
            <div className='d-flex justify-content-between'>
                <div className="pagetitle mb-5 mt-3" style={{ width: "fit-content" }}>
                    User Details
                </div>
                <button className='btn btn-success' style={{height : "50px"}} onClick={() => setToggle(!toggle)}>
                    Edit Profile
                </button>
            </div>
            {!toggle && <div className={"row"}>
                <div className="col-lg-2 col-md-2">
                    <div className="profile_image">
                        <Image src={details?.profile_pic !== "" ? process.env.REACT_APP_IMAGE_URL + details?.profile_pic : "/user.jpg"} alt="" className="rounded-circle img-fluid" />
                    </div>
                </div>
                <div className="col-lg-10 col-md-10">
                    <ul className="profile_list p-0 row custom-css">
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Customer Name</div>
                                <div className="name mt-2">{details?.customer_name !== "" ? details?.customer_name : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Customer Phone</div>
                                <div className="name mt-2">{details?.mobile && details?.mobile !== "" ? details?.mobile : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Salesman Name</div>
                                <div className="name mt-2">
                                    {details?.retailer !== "" ? details?.retailer : "N/A"}
                                    {/* <Button text="View Image" data-bs-toggle="modal" data-bs-target="#image-opener" /> */}
                                </div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Retailer Name</div>
                                <div className="name mt-2">
                                    {details?.retailer_name !== "" ? details?.retailer_name : "N/A"}
                                    {/* <Button text="View Image" data-bs-toggle="modal" data-bs-target="#image-opener" /> */}
                                </div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Total Amount</div>
                                <div className="name mt-2 text-lowercase">{details?.total_amount && details?.total_amount !== "" ? details?.total_amount : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Down Payment</div>
                                <div className="name mt-2">{details?.down_payment && details?.down_payment !== "" ? details?.down_payment : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">EMI Months</div>
                                <div className="name mt-2">{details?.emi_months && details?.emi_months !== "" ? details?.emi_months : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Finance</div>
                                <div className="name mt-2">{details?.finance && details?.finance !== "" ? details?.finance : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">C/O Name</div>
                                <div className="name mt-2">{details?.care_of && details?.care_of !== "" ? details?.care_of : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Whatsapp Number</div>
                                <div className="name mt-2">{details?.whatsapp_no && details?.whatsapp_no !== "" ? details?.whatsapp_no : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Alternate Number</div>
                                <div className="name mt-2">{details?.alternate_no && details?.alternate_no !== "" ? details?.alternate_no : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Occupation</div>
                                <div className="name mt-2">{details?.occupation && details?.occupation !== "" ? details?.occupation : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Address</div>
                                <div className="name mt-2">{details?.address && details?.address !== "" ? details?.address : "N/A"}</div>
                            </div>
                        </li></div>

                        <h5>Aadhar Information</h5>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Aadhar Number</div>
                                <div className="name mt-2">{details?.aadhar?.number && details?.aadhar?.number !== "" ? details?.aadhar?.number : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Front Photo</div>
                                <div className="name mt-2">{(details?.aadhar?.front_image && details?.aadhar?.front_image !== "") ? <Button text="View Image" onClick={() => setUrl(details?.aadhar?.front_image)} data-bs-toggle="modal" data-bs-target="#image-opener" /> : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Back Photo</div>
                                <div className="name mt-2">{(details?.aadhar?.back_image && details?.aadhar?.back_image !== "") ? <Button text="View Image" onClick={() => setUrl(details?.aadhar?.back_image)} data-bs-toggle="modal" data-bs-target="#image-opener" /> : "N/A"}</div>
                            </div>
                        </li></div>

                        <h5>Pan Card Information</h5>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Pancard Number</div>
                                <div className="name mt-2">{details?.pan_card?.number && details?.pan_card?.number !== "" ? details?.pan_card?.number : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Pancard Photo</div>
                                <div className="name mt-2">{(details?.pan_card?.image && details?.pan_card?.image !== "") ? <Button text="View Image" onClick={() => setUrl(details?.pan_card?.image)} data-bs-toggle="modal" data-bs-target="#image-opener" /> : "N/A"}</div>
                            </div>
                        </li></div>

                        <h5>Bank Statement Information</h5>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Account Number</div>
                                <div className="name mt-2">{details?.bank_account_number && details?.bank_account_number !== "" ? details?.bank_account_number : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">IFSC Code</div>
                                <div className="name mt-2">{details?.bank_ifsc_code && details?.bank_ifsc_code !== "" ? details?.bank_ifsc_code : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Statement Front Photo</div>
                                <div className="name mt-2">{(details?.statement?.front_image && details?.statement?.front_image !== "") ? <Button text="View Image" onClick={() => setUrl(details?.statement?.front_image)} data-bs-toggle="modal" data-bs-target="#image-opener" /> : "N/A"}</div>
                            </div>
                        </li></div><div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Statement Back Photo</div>
                                <div className="name mt-2">{(details?.statement?.back_image && details?.statement?.back_image !== "") ? <Button text="View Image" onClick={() => setUrl(details?.statement?.back_image)} data-bs-toggle="modal" data-bs-target="#image-opener" /> : "N/A"}</div>
                            </div>
                        </li></div>

                        <h5>Device Information</h5>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">IMEI Number 1</div>
                                <div className="name mt-2">{details?.device_information?.imei1 && details?.device_information?.imei1 !== "" ? details?.device_information?.imei1 : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">IMEI Number 2</div>
                                <div className="name mt-2">{details?.device_information?.imei2 && details?.device_information?.imei2 !== "" ? details?.device_information?.imei2 : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Device Model</div>
                                <div className="name mt-2">{details?.device_information?.device_model && details?.device_information?.device_model !== "" ? details?.device_information?.device_model : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Device Name</div>
                                <div className="name mt-2">{details?.device_information?.device_name && details?.device_information?.device_name !== "" ? details?.device_information?.device_name : "N/A"}</div>
                            </div>
                        </li></div>

                        <h5>Other Documents</h5>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Sealed Box Photo (Front)</div>
                                <div className="name mt-2">{(details?.other_documents?.sealed_box_photos?.front && details?.other_documents?.sealed_box_photos?.front !== "") ? <Button text="View Image" onClick={() => setUrl(details?.other_documents?.sealed_box_photos?.front)} data-bs-toggle="modal" data-bs-target="#image-opener" /> : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Sealed Box Photo (Back)</div>
                                <div className="name mt-2">{(details?.other_documents?.sealed_box_photos?.back && details?.other_documents?.sealed_box_photos?.back !== "") ? <Button text="View Image" onClick={() => setUrl(details?.other_documents?.sealed_box_photos?.back)} data-bs-toggle="modal" data-bs-target="#image-opener" /> : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Signature</div>
                                <div className="name mt-2">{(details?.other_documents?.signature && details?.other_documents?.signature !== "") ? <Button text="View Image" onClick={() => setUrl(details?.other_documents?.signature)} data-bs-toggle="modal" data-bs-target="#image-opener" /> : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Bill Photo</div>
                                <div className="name mt-2">{(details?.other_documents?.bill_photo && details?.other_documents?.bill_photo !== "") ? <Button text="View Image" onClick={() => setUrl(details?.other_documents?.bill_photo)} data-bs-toggle="modal" data-bs-target="#image-opener" /> : "N/A"}</div>
                            </div>
                        </li></div>
                        <div className="col-md-6"><li >
                            <div className="detail_wrapper">
                                <div className="small_heading">Customer Recieved Photo</div>
                                <div className="name mt-2">{(details?.other_documents?.customer_recieved_photo && details?.other_documents?.customer_recieved_photo !== "") ? <Button text="View Image" onClick={() => setUrl(details?.other_documents?.customer_recieved_photo)} data-bs-toggle="modal" data-bs-target="#image-opener" /> : "N/A"}</div>
                            </div>
                        </li></div>

                        <Link to={"/user-emi-management/" + id}>View All EMIs</Link>
                    </ul>
                </div>
            </div>}

            {toggle && <Editor datum={details} id={id} />}
            <ImagePopup src={url} />
        </>
    )
}

export default UserDetails