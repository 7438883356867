import * as moment from 'moment'
import React, { useEffect, useState } from 'react'
import ImageViewer from '../../common/ImageViewer';
import { useNavigate, useParams } from 'react-router-dom';
import { getRequest } from '../../apis/functions';
import APIS from '../../apis/routes';
import { toaster } from '../../apis/commonFunctions';
import Button from '../../common/Button';

const DetailsViewer = () => {

    const nav = useNavigate()

    const [details, setDetails] = useState({
        shop_bill: "",
        shop_image: "",
        shop_address: "Prabhat Market, Moradabad",
        owner_name: "",
        owner_pancard: "",
        shop_name: "Raj Communication",
        shop_trade_lic: "",
        shop_cheque: ""
    });

    const { id } = useParams();

    async function fetchDetails() {
        try {
            const { data } = await getRequest(APIS.SHOP + "/" + id);
            if (data.status) {
                setDetails(data.data)
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err, false)
        }
    }

    useEffect(() => {
        fetchDetails()
    }, [id]);

    return (
        <div className={"row"}>
            <div className="col-lg-12 col-md-10">
                <div className='row d-flex justify-content-between mt-4'>
                    <div className="pagetitle mb-3" style={{ width: "fit-content" }}>
                        Shop Details
                    </div>
                    <Button text="Edit Shop" className="btn btn-success" style={{ width: "fit-content" }} onClick={() => nav("/shop-management/edit/" + id)} />
                </div>
                <div className='text-center'>
                    <img src={details.shop_image} className='img-thumbnail shop-banner' />
                </div>
                <ul className="profile_list p-0 row custom-css mt-4">
                    <div className="col-md-6"><li >
                        <div className="detail_wrapper">
                            <div className="small_heading">Shop Name</div>
                            <div className="name mt-2">{details.shop_name}</div>
                        </div>
                    </li></div>
                    <div className="col-md-6"><li >
                        <div className="detail_wrapper">
                            <div className="small_heading">Shop Address</div>
                            <div className="name mt-2">{details.shop_address}</div>
                        </div>
                    </li></div>
                    <div className="col-md-6"><li >
                        <div className="detail_wrapper">
                            <div className="small_heading">Shop Bill</div>
                            <div className="name mt-2 text-lowercase"><ImageViewer src={details.shop_bill} /></div>
                        </div>
                    </li></div>
                    <div className="col-md-6"><li >
                        <div className="detail_wrapper">
                            <div className="small_heading">Shop Trade License</div>
                            <div className="name mt-2"><ImageViewer src={details.shop_trade_lic} /></div>
                        </div>
                    </li></div>
                    <div className="col-md-6"><li >
                        <div className="detail_wrapper">
                            <div className="small_heading">Shop Cheque</div>
                            <div className="name mt-2"><ImageViewer src={details.shop_cheque} /></div>
                        </div>
                    </li></div>
                    <div className="pagetitle mb-3">
                        Owner Details <br />
                    </div>
                    <div className="col-md-6"><li >
                        <div className="detail_wrapper">
                            <div className="small_heading">Name</div>
                            <div className="name mt-2">{details.owner_name}</div>
                        </div>
                    </li></div>
                    <div className="col-md-6"><li >
                        <div className="detail_wrapper">
                            <div className="small_heading">Pan Card</div>
                            <div className="name mt-2"><ImageViewer src={details.owner_pancard} /></div>
                        </div>
                    </li></div>
                </ul>
            </div>
        </div>
    )
}

export default DetailsViewer